@use '../node_modules/normalize.css/normalize.css';

[data-component-config] {
    display: none;
}

* {
    box-sizing: border-box;
}

address {
    font-style: normal;
}
